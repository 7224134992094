<template>
    <div id="news_detail">
        <div class="title">{{article.title}}</div>
        <div class="other">
            <div>来源：{{$store.state.tenantInfo.company}}</div>
            <div>时间：{{$moment(article.create_time).format('Y-MM-DD HH:mm')}}</div>
            <div>浏览量：{{article.view_num}} 次</div>
        </div>
        <div class="line"></div>
        <div class="detail" v-html="article.content">
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            article:{},
        }
    },
    created(){
        var id = this.$route.params.id;
        if(id){
            this.getDetail(id);
        }
    },
    mounted(){},
    watch: {
        $route(to,from){
            var id = this.$route.params.id;
            if(id){
                this.getDetail(id);
            }
        }
    },
    methods: {
        getDetail(id){
            this.$request.post({
                url:'/ReportApi/business/newdetails',
                params:{
                    id:id,
                },
                success: res => {
                    this.article = res;
                }
            })
        },
    }
}
</script>
<style lang="scss">
    .detail{
        img{
            width: 100% 
        }
    }
</style>
<style lang="scss" scoped>
#news_detail{
    border-radius: 6px;
    padding: 20px;
    background: #F2F2F2;
    border: 1px solid #DADADA;
    .title{
        text-align: center;
        color: #f80;
        font-weight: bold;
        margin-top: 30px;
        font-size: 18px;
    }
    .other{
        display: flex;
        width: 485px;
        margin: 0 auto;
        font-size: 14px;
        color: #666;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .line{
        border-bottom: 1px dashed #999;
        margin-bottom: 20px;
    }
}
</style>